import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet
      title="Page not found | Element Studio - Cheltenham Web Design &amp; Development"
      meta={[
        {
          name: 'description',
          content:
            'You&lsquo;ve stumbled across a missing page!',
        },
      ]}
    />
    <section className="error_wrapper">
      <div className="container-medium">
        <h1 className="type-intro">
          A wild <span className="type-highlight">404</span> error appears!
        </h1>
        <p className="island">You appear to have tried to visit a page that no longer exists!</p>
        <a href="/" className="btn-alpha" title="Go back">
          Go back
        </a>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
